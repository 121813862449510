import React from "react";
import Loader from "react-loader-spinner";
import tw, { css } from "twin.macro";

interface Props {
  height?: number | string;
  width?: number | string;
}
const styles = css`
  ${tw`flex justify-center items-center`}
  svg {
    ${tw`m-auto `}
  }
`;

export const PageLoader: React.FC<Props> = ({ height = 100, width = 100, ...rest }) => {
  return (
    <div css={styles} {...rest}>
      <Loader type="TailSpin" color={"rgb(7, 87, 91)"} height={height} width={width} />
    </div>
  );
};
